@import '@core/styles/colors.scss';

.apple-button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	font-weight: 700;
	color: black;
	font-size: 14px;
	line-height: 17px;
	width: 100%;
	box-sizing: border-box;
	height: 54px;
	border-radius: 12px;
	border: 1px solid $colorGray20;
	background: $colorPureWhite;
	// box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
	// color: $colorPureWhite;
	&:disabled {
		filter: blur(1px);
	}
}
