// Background color
$backgroundLight: #fff;
$backgroundDark: #1D2233;
$backgroundFullDark: #10131C;

// PU : PHARM UNI
$colorP1: #bb3a90;
$colorPLight: #fff0f7;
$colorPureWhite: #ffffff;
$colorAccent: #4ecdc4;
$colorSecondaryLight: #eefafa;
$colorSecondaryLecture: #18BBA8;
// Gray range
$colorGray10: #f7f6f3;
$colorGray15: #ecebe8;
$colorGray20: #cccbc9;
$colorGray25: #bfbfbf;
$colorGray30: #a6a5a3;
$colorGray40: #666664;
$colorGray50: #403f3f;
$colorGray60: #262626;
$colorGray70: #0d0d0d;

// Messages
$colorAlert: #d20101;
$colorAlert30: #D82F56;
$colorAlert40: #D20101;
$colorAlert50: #850000;
$colorAlertLight: #ffe5e5;
$colorWarning10: #fff5e5;
$colorWarning30: #FFCF80;
$colorWarning40: #faa61a;
$colorWarning50: #ad6c00;

$colorSuccess: #578a1d;
$colorSuccess10: #e6f0da;
$colorSuccess40: #91BC60;
