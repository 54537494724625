@import '@core/styles/colors.scss';

.passwordinput-wrapper {
	& > label {
		margin: 8px 0px;
		color: $colorGray70;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
	}
	& > .password-input-wrapper {
		& > svg {
			margin-left: -38px;
			cursor: pointer;
		}
		& > input {
			width: 100%;
			height: 40px;
			padding: 8px 12px;
			margin-bottom: 8px;
			background-color: $colorPureWhite;
			color: $colorGray70;
			font-weight: 400;
			font-size: 16px;
			line-height: 20px;
			border: 1px solid $colorGray20;
			border-radius: 8px;
			outline: none;
			&:focus {
				border: 2px solid $colorP1;
			}
			&:-webkit-autofill {
				box-shadow: 0 0 0 30px $colorPureWhite inset;
				color: $colorGray70;
				line-height: 20px;
				-webkit-text-fill-color: $colorGray70;
				-webkit-text-fill-font-weight: 400;
				-webkit-text-fill-font-size: 16;
			}
			&.invalid {
				background-color: $colorAlertLight;
				border: 2px solid $colorAlert;
			}
		}
	}
	& > .errors-wrapper {
		min-height: 24px;
		& > p {
			display: flex;
			margin: 2px 0;
			color: $colorGray40;
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
			gap: 0.25rem;
			&.error {
				color: $colorAlert;
			}
			&.passed {
				color: $colorSuccess;
			}
		}
	}
}
