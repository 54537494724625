@import '@core/styles/colors.scss';

.facebook-button {
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	width: 100%;
	box-sizing: border-box;
	height: 54px;
	border-radius: 12px;
	border: 1px solid $colorGray20;
	background-color: #1877f2;
	color: $colorPureWhite;
	&:disabled {
		filter: blur(1px);
	}
}
