@import '@core/styles/colors.scss';
@import '@core/styles/devicesize.scss';

.login-wrapper {
	background-color: $colorPureWhite;
	padding: 32px 32px 24px;
	// margin-top: 3.5rem;
	@media screen and (max-width: $widthMobileMax) {
		margin-top: 0;
	}
	& > .login {
		width: 360px;
		@media screen and (max-width: $widthTabletMax) {
			width: 324px;
		}
		@media screen and (max-width: $widthMobileMax) {
			width: 82vw;
		}
		& > h1 {
			font-style: normal;
			font-weight: 900;
			font-size: 24px;
			line-height: 29px;
			color: $colorGray70;
			margin-bottom: 32px;
		}
		& > form {
			& > a {
				color: $colorP1;
				text-decoration: none;
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
			}
		}
		> .divider {
			display: flex;
			align-items: center;
			text-align: center;
			font-size: 12px;
			color: $colorGray40;
			font-weight: 400;
			&::after,
			&::before {
				content: '';
				border: 1px solid rgba(13, 13, 13, 0.1);
				flex: 1;
			}
			&:not(:empty)::before {
				margin-right: 1em;
			}
			&:not(:empty)::after {
				margin-left: 1em;
			}
		}
		& >.social-login{
			&> button {
				
			}
		}
		& > p:last-of-type {
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			& > a {
				padding-left: 8px;
				text-decoration: none;
				color: $colorP1;
			}
		}
	}
}
