@import '@core/styles/colors.scss';

.google-button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	width: 100%;
	box-sizing: border-box;
	height: 54px;
	border-radius: 12px;
	border: 1px solid $colorGray20;
	background-color: $colorPureWhite;
	color: $colorGray40;
	&:disabled {
		filter: blur(1px);
	}
}
